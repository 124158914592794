.logo {
    width: 160px;
    margin: 16px 0;
    max-width: 100%;
}

.dynamic-delete-button {
    cursor: pointer;
    position: relative;
    top: 4px;
    font-size: 24px;
    color: #999;
    transition: all 0.3s;
}

.dynamic-delete-button:hover {
    color: #777;
}

.dynamic-delete-button[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
}

.login-form-forgot {
    float: right;
}

.login-form-button {
    width: 100%;
}

#root, body, html {
    height: 100%;
}

th.column-money,
td.column-money {
    text-align: right !important;
}

td.column-number {
    text-align: right !important;
}

tr.green {
    background-color: #d0ffd2;
}

.ant-table-tfoot tr td {
    padding: 8px 8px;
    border-right: 1px solid #e8e8e8;
    border-top: 1px solid #e8e8e8;
    font-weight: 500;
}